import { lazy } from 'react';

import { PATH_PAYMENTS } from './paths';

const PaymentRoutes = {
  path: PATH_PAYMENTS.root,
  routes: [
    {
      exact: true,
      path: PATH_PAYMENTS.payments,
      component: lazy(() => import('../views/payments'))
    },
  ]
};

export default PaymentRoutes;
