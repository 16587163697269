import NProgress from 'nprogress';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Suspense, Fragment, lazy, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import GuestGuard from '../guards/GuestGuard';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import HomeLayout from '../layouts/landingPage';
import CommunityHomeLayout from '../layouts/community/home';

import { PATH_PAGE, PATH_AUTH, PATH_COMMUNITY_DASHBOARD, COMMUNITY_LANDING_PAGE, COMMUNITY_LANDING_PAGE_EXPERIENCE } from './paths';
import DashboardRoutes from './communityAdminDashboard.routes';
import HomeRoutes from './home.routes';
import BackOfficeRoutes from './backOffice.routes';
import BusinessRoutes from './business.routes';
import AccessControl from '../components/AccessControl';
import CreateRoutes from './create.route';
import BusinessDashboardRoutes from './businessDashboard.routes';
import CommunityMemberDashboardLayout from '../layouts/community/memberDashboard';
import ExperienceRoutes from './experience.routes';
import PaymentRoutes from './payments.routes';
// import MemberDashboardRoutes from './communityMemberDashboard.routes';
// import DocsRoutes from './backOffice.routes';
// import KitchenRoutes from './kitchenDashboard.routes';

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: 30009,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return <Route {...props} />;
}

export default function AppRoute() {
  const { pathname } = useLocation();
  function renderRoutes(routes = []) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, idx) => {
            const Component = route.component;
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const routeHasPermission = Array.isArray(route.permission);
            const RoleBasedProtection = routeHasPermission ? AccessControl : Fragment;
            const roleBasedProtectionProps = {
              permitedRoles: route.permission
            };

            return (
              <RouteProgress
                key={`routes-${idx}`}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <RoleBasedProtection {...(routeHasPermission && roleBasedProtectionProps)} >
                          <Component {...props} />
                        </RoleBasedProtection>
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      </Suspense>
    );
  }
  return renderRoutes(routes(pathname));
}

const routes = (pathname) => [
  {
    ...BusinessDashboardRoutes,
    routes: [
      ...BusinessDashboardRoutes.routes,
      {
        exact: true,
        path: PATH_COMMUNITY_DASHBOARD?.root,
        component: () => <Redirect to={`${pathname}/overview`} />
      }
    ]
  },
  BusinessRoutes,
  PaymentRoutes,
  ExperienceRoutes,
  // {
  //   ...MemberDashboardRoutes,
  //   routes: [
  //     ...MemberDashboardRoutes.routes,
  //     {
  //       exact: true,
  //       path: PATH_COMMUNITY_MEMBER_DASHBOARD?.root,
  //       component: () => <Redirect to={`${pathname}/overview`} />
  //     }
  //   ]
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: PATH_AUTH.login,
    component: lazy(() => import('../views/authentication/Login'))
  },
  {
    path: PATH_PAGE.join,
    exact: true,
    component: lazy(() => import('../views/authentication/JoinCommunity'))
  },
  {
    guard: GuestGuard,
    path: `${PATH_AUTH.register}`,
    component: lazy(() => import('../views/authentication/Register'))
  },
  {
    path: PATH_PAGE.termsOfService,
    exact: true,
    component: lazy(() => import('../views/TermsOfService'))
  },
  {
    path: PATH_PAGE.accountDeletion,
    exact: true,
    component: lazy(() => import('../views/DeleteAccount'))
  },
  {
    path: PATH_PAGE.childSafetyStandards,
    exact: true,
    component: lazy(() => import('../views/policies/ChildSafetyStandars'))
  },
  {
    exact: true,
    path: PATH_AUTH.forgotPassword,
    component: lazy(() => import('../views/authentication/ForgotPassword'))
  },
  {
    exact: true,
    path: `${PATH_AUTH.resetPassword}`,
    component: lazy(() => import('../views/authentication/ResetPassword'))
  },
  {
    exact: true,
    path: PATH_AUTH.verify,
    component: lazy(() => import('../views/authentication/VerifyCode'))
  },
  {
    exact: true,
    path: PATH_PAGE.page404,
    component: lazy(() => import('../views/Page404'))
  },
  {
    exact: true,
    path: PATH_PAGE.page500,
    component: lazy(() => import('../views/Page500'))
  },

  {
    exact: true,
    path: PATH_AUTH.root,
    component: () => <Redirect to={PATH_AUTH.login} />
  },

  // Resident landing route
  {
    exact: false,
    path: PATH_PAGE.myCommunely,
    guard: AuthGuard,
    layout: HomeLayout,
    component: lazy(() => import('../views/myCommunely/Home'))
  },
  // This route habdles all creates e.g create community
  // Home Routes
  CreateRoutes,
  {
    ...DashboardRoutes,
    routes: [
      ...DashboardRoutes.routes,
      {
        exact: true,
        path: PATH_COMMUNITY_DASHBOARD?.root,
        component: () => <Redirect to={`${pathname}/overview`} />
      }
    ]
  },
    // {
  //   ...MemberDashboardRoutes,
  //   routes: [
  //     ...MemberDashboardRoutes.routes,
  //     {
  //       exact: true,
  //       path: PATH_COMMUNITY_MEMBER_DASHBOARD?.root,
  //       component: () => <Redirect to={`${pathname}/overview`} />
  //     }
  //   ]
  // },
  
  {
    exact: true,
    path: "/estates/:code",
    component: lazy(() => import('./EstateRedirect'))
  },
  {
    exact: false,
    // guard: AuthGuard,
    path: COMMUNITY_LANDING_PAGE,
    layout: CommunityMemberDashboardLayout,
    component: lazy(() => import('../views/community/home'))
  },
  // {
  //   exact: false,
  //   // guard: AuthGuard,
  //   path: COMMUNITY_LANDING_PAGE_EXPERIENCE,
  //   layout: CommunityMemberDashboardLayout,
  //   component: lazy(() => import('../views/community/home'))
  // },
  BackOfficeRoutes,
  HomeRoutes,
];
