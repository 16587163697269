function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/back-office';
export const ROOTS_COMMUNITY = '/community';
export const ROOT_PAYMENT = '/payments';
export const ROOTS_BUSINESS = '/businesses';
export const ROOTS_EXPERIENCE = '/experiences';
export const COMMUNITY_LANDING_PAGE = `${ROOTS_COMMUNITY}/:communityCode`;
export const COMMUNITY_LANDING_PAGE_EXPERIENCE = `${ROOTS_COMMUNITY}/:communityCode/experiences/:experienceId`;
const ROOTS_DASHBOARD = `${ROOTS_COMMUNITY}/:communityCode/admin`;
const ROOTS_MEMBER_DASHBOARD = `${ROOTS_COMMUNITY}/:communityCode/member`;
const ROOTS_BUSINESS_DASHBOARD = `${ROOTS_BUSINESS}/:businessId/dashboard`;

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  verify: '/verify'
};

export const PATH_PAGE = {
  myCommunely: '/my-communely',
  join: `/join`,
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  termsOfService: '/terms-of-service',
  accountDeletion: '/delete-account',
  childSafetyStandards: '/child-safety-standards',
  pricing: '/pricing',
  payment: '/payment',
  page404: '/404',
  page500: '/500'
};

export const PATH_HOME = {
  dashboard: ROOTS_DASHBOARD
};

export const PATH_BUSINESS = {
  root: ROOTS_BUSINESS,
  busniesses: ROOTS_BUSINESS,
  busniess: path(ROOTS_BUSINESS, '/:businessId'),
  createBusiness: path(ROOTS_BUSINESS, '/create')
};

export const PATH_PAYMENTS = {
  root: ROOT_PAYMENT,
  payments:  path(ROOT_PAYMENT),
  payment: path(ROOT_PAYMENT, '/:paymentId'),
};

export const PATH_EXPERIENCE = {
  root: ROOTS_EXPERIENCE,
  experience: path(ROOTS_EXPERIENCE, '/:experienceId'),
};

export const PATH_COMMUNITY_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  overview: {
    root: path(ROOTS_DASHBOARD, '/overview'),
  },
  personnel: {
    root: path(ROOTS_DASHBOARD, '/personnel/members'),
    member: path(ROOTS_DASHBOARD, '/personnel/members/:personnelId'),
    members: path(ROOTS_DASHBOARD, '/personnel/members'),
    admins: path(ROOTS_DASHBOARD, '/personnel/admins'),
    admin: path(ROOTS_DASHBOARD, '/personnel/admins/:personnelId'),
    securities: path(ROOTS_DASHBOARD, '/personnel/securities'),
    security: path(ROOTS_DASHBOARD, '/personnel/securities/:personnelId'),
  },
  businesses: {
    root: path(ROOTS_DASHBOARD, '/businesses'),
    // business: path(ROOTS_DASHBOARD, '/businesses/:businessId'),
  },
  cashFlow: {
    root: path(ROOTS_DASHBOARD, '/cash-flow')
  },
  activities: {
    root: path(ROOTS_DASHBOARD, '/activities')
  },
  announcements: {
    root: path(ROOTS_DASHBOARD, '/announcements'),
    announcement: path(ROOTS_DASHBOARD, '/announcements/:announcementId'),
    edit: path(ROOTS_DASHBOARD, '/announcements/:announcementId/edit'),
    new: path(ROOTS_DASHBOARD, '/announcements/new')
  },
  integrations: {
    cctv: path(ROOTS_DASHBOARD, '/integrations/cctv'),
    barriers: path(ROOTS_DASHBOARD, '/integrations/barriers'),
    alarms: path(ROOTS_DASHBOARD, '/integrations/alarms'),
    readers: path(ROOTS_DASHBOARD, '/integrations/readers')
  },
  management: {
    zones: path(ROOTS_DASHBOARD, '/management/zones'),
    zone: path(ROOTS_DASHBOARD, '/management/zones/:zoneId'),
    street: path(
      ROOTS_DASHBOARD,
      '/management/zones/:zonesId/streets/:streetId'
    ),
    block: path(
      ROOTS_DASHBOARD,
      '/management/zones/:zonesId/streets/:streetId/blocks/:blockId'
    ),
    personels: path(ROOTS_DASHBOARD, '/management/personels'),
    personel: path(ROOTS_DASHBOARD, '/management/personels/:personelId'),
    account: path(ROOTS_DASHBOARD, '/management/account')
  }
};

export const PATH_COMMUNITY_MEMBER_DASHBOARD = {
  root: ROOTS_MEMBER_DASHBOARD,
  overview: {
    root: path(ROOTS_MEMBER_DASHBOARD, '/overview'),
  },
  household: {
    root: path(ROOTS_MEMBER_DASHBOARD, '/household'),
  },
  invites: {
    root: path(ROOTS_MEMBER_DASHBOARD, '/invites'),
  },
  cashFlow: {
    root: path(ROOTS_MEMBER_DASHBOARD, '/payments')
  },
  announcements: {
    root: path(ROOTS_MEMBER_DASHBOARD, '/announcements'),
    announcement: path(ROOTS_MEMBER_DASHBOARD, '/announcements/:announcementId'),
  },
  activities: {
    root: path(ROOTS_MEMBER_DASHBOARD, '/activities')
  },
  settings: {
    account: path(ROOTS_MEMBER_DASHBOARD, '/settings')
  }
};

export const PATH_BUSINESS_DASHBOARD = {
  root: ROOTS_BUSINESS_DASHBOARD,
  overview: {
    root: path(ROOTS_BUSINESS_DASHBOARD, '/overview'),
  },
  offerings: {
    root: path(ROOTS_BUSINESS_DASHBOARD, '/offerings'),
    offering: path(ROOTS_BUSINESS_DASHBOARD, '/offerings/:offeringId'),
  },
  orders: {
    root: path(ROOTS_BUSINESS_DASHBOARD, '/orders'),
    order: path(ROOTS_BUSINESS_DASHBOARD, '/order'),
  },
  community: {
    root: path(ROOTS_BUSINESS_DASHBOARD, '/community'),
    offering: path(ROOTS_BUSINESS_DASHBOARD, '/community/:communityId'),
  },
  cashFlow: {
    root: path(ROOTS_BUSINESS_DASHBOARD, '/cash-flow')
  },
  management: {
    personels: path(ROOTS_BUSINESS_DASHBOARD, '/management/personels'),
    account: path(ROOTS_BUSINESS_DASHBOARD, '/management/account')
  }
};

export const PATH_CREATE = {
  community: path(ROOTS_COMMUNITY, '/create'),
};

export const PATH_BACKOFFICE = {
  root: ROOTS_DOCS,
  overview: path(ROOTS_DOCS, '/overview'),
  users: path(ROOTS_DOCS, '/users'),
  businesses: path(ROOTS_DOCS, '/businesses'),
};
